@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: black;
    
}

@font-face {
    font-family: 'Asteriod';
    src: url("./assets/fonts/SdAsteroidB612-wo3e2.otf") format('opentype');
    font-weight: normal;
    font-style: normal;
}

.asteriod{
    font-family: 'Asteriod', sans-serif;
    letter-spacing: 0.1em;
}


@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');



body {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}


/* From Uiverse.io by Smit-Prajapati */ 
.card {
    
    width: 350px;
    border-radius: 20px;
    background: #000000;
    
    padding: 5px;
    overflow: hidden;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 20px 0px;
    transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .card:hover {
    transform: scale(1.01);
  }
   
   
  
  .card .top-section {
    height: 350px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    background-position: center;
    background-position-y: 25%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  
  .card .top-section .cardborder{
    border-bottom-right-radius: 10px;
    height: 30px;
    width: 130px;
    background: white;
    background: #000000;
    position: relative;
    transform: skew(-40deg);
    box-shadow: -10px -10px 0 0 #000000;
  }
  
  .card .top-section .cardborder:before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    top: 0;
    right: -15px;
    background: rgba(255, 255, 255, 0);
    border-top-left-radius: 10px;
    box-shadow: -5px -5px 0 2px #000000;
  }
  
  .card .top-section::before {
    content: "";
    position: absolute;
    top: 30px;
    left: 0;
    background: rgba(255, 255, 255, 0);
    height: 15px;
    width: 15px;
    border-top-left-radius: 15px;
    box-shadow: -5px -5px 0 2px #000000;
  }
  
  .card .top-section .icons {
    position: absolute;
    top: 0;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
  }
  
  .card .top-section .icons .logo {
    height: 100%;
    width: 43%;
    aspect-ratio: 1;
    padding: 7px 0 7px 15px;
  }
  
  .card .top-section .icons .logo .top-section {
    height: 100%;
  }
  
  .card .top-section .icons .social-media {
    height: 100%;
    padding: 8px 15px;
    display: flex;
    gap: 7px;
  }
  
  
  .card .bottom-section {
    margin-top: 15px;
    padding: 10px 5px;
  }

  .card .bottom-section {
    margin-top: 15px;
    padding: 10px 5px;
  }
  
  .card .bottom-section .title {
    display: block;
    font-size: 16px;
    font-weight: bolder;
    color: red;
    text-align: center;
    letter-spacing: 2px;
  }




.skyimage{
  background-image: url('./assets/images/home/sky1.jpg');
}
 
 /* input */
 .wave-group {
  position: relative;
}

.wave-group .editinput {
  font-size: 16px;
  padding: 10px 10px 10px 5px;
  display: block;
  font-weight: normal;
  border: none;
  color: aliceblue;
  border-bottom: 1px solid #515151;
  background: transparent;
}

.wave-group .editinput:focus,
.wave-group .editinput:not(:placeholder-shown) {
  outline: none;
}

.wave-group .editlabel {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  display: flex;
  transition: 0.2s ease all;
}

.wave-group .editinput:focus ~ .editlabel,
.wave-group .editinput:not(:placeholder-shown) ~ .editlabel {
  transform: translateY(-20px);
  font-size: 16px;
  font-weight: 800;
  color: #fd0202;
}

.wave-group .editbar {
  position: relative;
  display: block;
}

.wave-group .editbar:before,
.wave-group .editbar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #c12d2d;
  transition: 0.2s ease all;
}

.wave-group .editbar:before {
  left: 50%;
}

.wave-group .editbar:after {
  right: 50%;
}

.wave-group .editinput:focus ~ .editbar:before,
.wave-group .editinput:focus ~ .editbar:after,
.wave-group .editinput:not(:placeholder-shown) ~ .editbar:before,
.wave-group .editinput:not(:placeholder-shown) ~ .editbar:after {
  width: 50%;
}

*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}



.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px; 
  height: 60px;
  background: #050000;
  border: 1px solid #fff;
  border-radius: 50%;
  overflow: hidden; 
  box-shadow: 0 0 0 1px #ff4c4c; 
}

.water {
  position: absolute;
  bottom: 0; 
  left: 0;
  width: 100%;
  height: 100%; 
  overflow: hidden; 
}

.wave {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 200%; 
  height: 200%; 
  background: rgba(255, 50, 50, 0.8); 
  border-radius: 45%; 
  transform: translateX(-50%) translateY(100%);
}

.x-image {
  position: absolute;
  z-index: 30;
  top: 1%; 
  left: 50%;
  transform: translateX(-50%);
  width: 50px; 
}

.wave1 {
  border-radius: 45%;
  animation-delay: 0s; 
  animation: waveMovement 4s linear infinite; 
}

.wave2 {
  border-radius: 40%;
  animation-delay: 1s; 
  animation: waveMovement 4s linear infinite; 
}

@keyframes waveMovement {
  0% {
    transform: translateX(-60%) translateY(90%) rotate(0deg);
  }
  25% {
    transform: translateX(-55%) translateY(50%) rotate(90deg);
  }
  50% {
    transform: translateX(-50%) translateY(0%) rotate(180deg);
  }
  75% {
    transform: translateX(-45%) translateY(50%) rotate(270deg);
  }
  100% {
    transform: translateX(-40%) translateY(90%) rotate(360deg);
  }
}
